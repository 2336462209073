import A1 from "./A1.svg?raw"
import A2 from "./A2.svg?raw"
import A3 from "./A3.svg?raw"
import A4 from "./A4.svg?raw"
import A5 from "./A5.svg?raw"
import A6 from "./A6.svg?raw"
import A7 from "./A7.svg?raw"
import A8 from "./A8.svg?raw"
import A9 from "./A9.svg?raw"
import B1 from "./B1.svg?raw"
import C1 from "./C1.svg?raw"
import C2 from "./C2.svg?raw"
import C3 from "./C3.svg?raw"
import D1 from "./D1.svg?raw"
import D2 from "./D2.svg?raw"
import D3 from "./D3.svg?raw"
import E1 from "./E1.svg?raw"
import E2 from "./E2.svg?raw"
import E3 from "./E3.svg?raw"
import E4 from "./E4.svg?raw"
import E5 from "./E5.svg?raw"
import E6 from "./E6.svg?raw"

export default {
    A1, A2, A3, A4, A5, A6, A7, A8, A9,
    B1, C1, C2, C3, D1, D2, D3,
    E1, E2, E3, E4, E5, E6,

}
